require('../bootstrap');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/autosize.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/charts.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/charts-dark.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/dashkit.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/demo.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/dropdowns.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/dropzone.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/flatpickr.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/highlight.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/kanban.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/list.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/map.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/navbar.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/polyfills.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/popover.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/quill.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/select2.js');
require('../../theme/Dashkit/Dashkit-1.4.2/src/assets/js/tooltip.js');

require('../app/customizer.js');

require('../../../node_modules/jarallax/dist/jarallax.js');
require('../../../node_modules/jarallax/dist/jarallax-element.js');
require('../../../node_modules/jarallax/dist/jarallax-video');
