function toggleColorScheme(boot = false)
{
    var colorScheme = (localStorage.getItem('dashkitColorScheme')) ? localStorage.getItem('dashkitColorScheme') : 'dark';
    var stylesheetLight = document.querySelector('#stylesheetLight');
    var stylesheetDark = document.querySelector('#stylesheetDark');

    if (boot)
    {
        if (colorScheme == 'light') {
            stylesheetLight.disabled = false;
            stylesheetDark.disabled = true;
            colorScheme = 'light';
            $(".toggle_light_dark .icon i").removeClass("fe-moon").addClass("fe-sun");

        }
        else {
            stylesheetLight.disabled = true;
            stylesheetDark.disabled = false;
            colorScheme = 'dark';
            $(".toggle_light_dark .icon i").removeClass("fe-sun").addClass("fe-moon");
        }
    } else {

        if (colorScheme == 'light') {
            stylesheetLight.disabled = true;
            stylesheetDark.disabled = false;
            colorScheme = 'dark';
            $(".toggle_light_dark .icon i").removeClass("fe-sun").addClass("fe-moon");

        } else {
            stylesheetLight.disabled = false;
            stylesheetDark.disabled = true;
            colorScheme = 'light';
            $(".toggle_light_dark .icon i").removeClass("fe-moon").addClass("fe-sun");
        }

    }

    localStorage.setItem('dashkitColorScheme', colorScheme);

}

function toggleSidebarSize(boot = false)
{
    var sidebarSize = (localStorage.getItem('dashkitSidebarSize')) ? localStorage.getItem('dashkitSidebarSize') : 'small';
    var sidebar = document.querySelector('#sidebar');
    var sidebarSmall = document.querySelector('#sidebarSmall');
    var sidebarCategory = document.querySelector('#sidebarCategory');
    var topnav = document.querySelector('#topnav');

    if (boot)
    {
        if (sidebarSize == 'base') {
            sidebarSmall.setAttribute('style', 'display: none !important');
            sidebar.setAttribute('style', 'display: block !important');
            sidbarSize = 'base';
            $("#topbar").addClass("sidebar-open");
        } else {
            sidebar.setAttribute('style', 'display: none !important');
            sidebarSmall.setAttribute('style', 'display: block !important');
            sidbarSize = 'small';
            $("#topbar").removeClass("sidebar-open");
        }
    }
    else {

        if (sidebarSize == 'base') {
            sidebarSmall.setAttribute('style', 'display: block !important');
            sidebar.setAttribute('style', 'display: none !important');
            sidbarSize = 'small';
            $("#topbar").removeClass("sidebar-open");
        } else if (sidebarSize == 'small') {
            sidebar.setAttribute('style', 'display: block !important');
            sidebarSmall.setAttribute('style', 'display: none !important');
            sidbarSize = 'base';
            $("#topbar").addClass("sidebar-open");
        }

    }

    sidebarCategory.setAttribute('style', 'display:flex !important');
    topnav.setAttribute('style', 'display:none !important');

    localStorage.setItem('dashkitSidebarSize', sidbarSize);
}

$(document).ready(function() {

    localStorage.setItem('dashkitNavPosition', 'combo');

    toggleColorScheme(true);
    toggleSidebarSize(true);

    $(".toggle_sidebar_size").click(function() {
        toggleSidebarSize();
    });

    $(".toggle_light_dark").click(function() {
        toggleColorScheme();
    });

});
